
import Layout from './Layout';

import { BrowserRouter as Router, Routes, Route, Navigate} from "react-router-dom";
import NewApplication from './NewApplication/NewApplication'
import './App.css';
import SentApplication from './NewApplication/SentApplication';
import SignIn from './NewApplication/SignIn';
import SignUp from './NewApplication/SignUp';
import Video from './NewApplication/Videos';

function App() {
  return (
   
    <Router>
<Routes>       
<Route path="/" element={ <Layout replace to="/home" />} />
<Route path="/sign-in" element={ <SignIn replace to="/sign-in" />} />
<Route path="/session/:token/:name" element={ <SignUp replace to="/sign-up" />} />
<Route path="/session/video" element={ <Video replace to="/session" />} />
</Routes>
</Router>
  );
}

export default App;
