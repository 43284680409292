import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import InputForm from '../Form/inputForm'
import styles from "../Layout/layout.module.css"
import swal from 'sweetalert';
import Headers from "../Layout/Header";
import Footer from "../Layout/Footer";

async function storeApplicant(credentials) {

  
    return fetch(`${process.env.REACT_APP_BASE_URL}login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(credentials)
    })
      .then(data => data.json())
   }
  
export default function SignIn(){

 

      const [count, setCount]=useState(1);
    const [loading, setLoading] = useState(false);
  
  const navigate=useNavigate();
    async function Onsubmit(credential, e){

        navigate('/sent-application');
      e.preventDefault();  
      setLoading(true);
      const response = await storeApplicant(credential);
    
     if (response.data) {

            navigate('/sent-application');
          window.location.reload();
      } else {
        setLoading(false)
        swal("Failed", JSON.stringify(response.errors[0]), "error");
      }
      response.errors &&  swal("Failed", JSON.stringify(response.errors[0]), "error");
    }
    useEffect(() => {
      window.scrollTo(0, 0)
    }, [])
  
    


      async function handleSubmit(event) {
          event.preventDefault();

        
          const data = new FormData(event.target);
        
          const requestOptions = {
            method: 'POST',
            // headers: { 'Content-Type': 'application/json' },
            body: data
        };
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}`,requestOptions);
        const datas = await response.json();
       if(response.ok)
       { 
        navigate('/sent-application');
       }
     else{
      alert('application not sent. please try sending the application again')
     }
        }
      
      
          return (
            <>
            <Headers/>
            <div className={styles.applicationPage} >
        <div className={styles.formHeight}> 
       
        <div className={styles.form}>  
        <div className={styles.formHeader}>SIGN IN</div>
            <form  className={styles.form} onSubmit={handleSubmit}>

           
              <div className={styles.inputGroup}>
              <label htmlFor="email">Email</label>
              <input required id="email" name="email" type="email" />
              </div>
              <div className={styles.inputGroup}>
              <label htmlFor="password">Password</label>
              <input required id="password" name="password" type="password" />
              </div>

              <div className={styles.inputGroup}>
              <button  className={styles.applicationButton}>Sign In</button>
              </div>
            </form>
            </div>
            </div>
            </div>

            <Footer/>
            </>
          )
        
}


