import Headers from "./Header";
import Main from "./Main";
import Footer from "./Footer";
import styles from "./layout.module.css"
import Row from "./Maincontentcomponents/Row"
import Parallaxrow from "./Maincontentcomponents/Parallaxrow"
import Parallaxword from "./Maincontentcomponents/Parallaxword"
import { Carousel } from 'react-responsive-carousel';

import "react-responsive-carousel/lib/styles/carousel.min.css";
import 'font-awesome/css/font-awesome.min.css';
import Square from "./Headercomponents/square"
import { useAnimation, motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { useEffect, useState } from "react"
import Animation1 from "./Maincontentcomponents/Animation1"
import Animation2 from "./Maincontentcomponents/Animation2"
import HeaderAnimation from "./Maincontentcomponents/HeaderAnimation"
import Logos from "../resources/logo.png"
import { useNavigate } from "react-router-dom"
import { useRef } from "react"

import voteImg from '../resources/images/vote.png'
import registerImg from '../resources/images/register-icon-6.jpg'
import payImg from '../resources/images/pay-15.png'
import voteVideo from '../resources/videos/voteVideo.mp4'

import cocoa1 from '../resources/images/pbc/cocoa1.jpg'
import cocoa2 from '../resources/images/pbc/cocoa2.jpg'
import cocoa3 from '../resources/images/pbc/cocoa3.webp'
import pdf from '../resources/images/pbc/pdf.png'
import agmPdf from '../resources/agmdoc.pdf'
import pdf1 from '../resources/pdf/Notice of AGM 2018.pdf'
import pdf2 from '../resources/pdf/Notice of AGM 2019.pdf'
import pdf3 from '../resources/pdf/Notice of AGM 2020.pdf'
import pdf4 from '../resources/pdf/Notice of AGM 2021.pdf'
import pdf5 from '../resources/pdf/Notice of AGM 2022.pdf'
import AMENDED_NOTICE from "../resources/pdf/AMENDED NOTICE_OF_ANNUAL_GENERAL_MEETING-LATEST.pdf"
import MDPDF from "../resources/pdf/MANAGING DIRECTORS REVIEW OF OPERATIONS.pdf"

import someonePaying from '../resources/images/pay2.jpg'
import someoneVoting from '../resources/images/vote2.jpg';
import someoneRegistering from '../resources/images/register3.jpg'
import CountdownTimer from "../Component/CountDownTimer";

export default function Layout() {

    const service = useRef(null);
    const address = useRef(null);

    const THREE_DAYS_IN_MS = 3 * 24 * 60 * 60 * 1000;
    const NOW_IN_MS = new Date().getTime();

    const dateTimeAfterThreeDays = NOW_IN_MS + THREE_DAYS_IN_MS;

    const ourService = () => {

        service.current?.scrollIntoView({ behavior: 'smooth' });
    };
    const ourAddress = () => {

        address.current?.scrollIntoView({ behavior: 'smooth' });
    };

    const calculateTimeLeft = () => {
        const difference = +new Date("2023-12-21T10:10:00+00:00") - +new Date();

        let timeLeft = {};

        if (difference > 0) {
            timeLeft = {
                days: Math.floor(difference / (1000 * 60 * 60 * 24)),
                hours: Math.floor(
                    (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
                ),

                minutes: Math.floor((difference / 1000 / 60) % 60),
                seconds: Math.floor((difference / 1000) % 60),
            };
        }
        return timeLeft;
    };

    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

    useEffect(() => {
        setTimeout(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000);
    });


    //header

    const [navbar, setNavbar] = useState(false)
    const modifyHeader = () => {
        // console.log(window.scrollY);
        if (window.scrollY < 80) {
            setNavbar(true);
        }
        else {
            setNavbar(false);
        }
    }

    window.addEventListener("scroll", modifyHeader)
    const [display, setDisplay] = useState('none')

    function handleClick() {

        if (display === 'none') {

            setDisplay('block')

        } else {

            setDisplay('none')

        }

    }


    const navigate = useNavigate();


    function Apply() {
        navigate('/new-application');
    }


    function SignIn() {
        navigate('/sign-in');
    }

    function SignUp() {
        navigate('/sign-up');
    }
    const boxVariant = {
        visible: { opacity: 1, y: 0, transition: { duration: 2 } },
        hidden: { opacity: 0.2, y: 20 }
    };
    const control = useAnimation();
    const [ref, inView] = useInView();
    useEffect(() => {
        if (inView) {
            control.start("visible");
        } else {
            control.start("hidden");
        }
    }, [control, inView]);


    const afterFeedback = <div className={styles.afterfeedbackTab} >
        <div>Thanks!!</div>

    </div>

    const feedback = <form className={styles.feedbackTab} onSubmit={handleSubmit}>

        <div className={styles.rating}>
            <div className={styles.rateHeading}>  RATE US</div>

            <div className={styles.rateContent}>
                <div className={styles.rate}>
                    <input type="radio" id="star5" name="rate" value="5" />
                    <label for="star5" title="text">5 stars</label>
                    <input type="radio" id="star4" name="rate" value="4" />
                    <label for="star4" title="text">4 stars</label>
                    <input type="radio" id="star3" name="rate" value="3" />
                    <label for="star3" title="text">3 stars</label>
                    <input type="radio" id="star2" name="rate" value="2" />
                    <label for="star2" title="text">2 stars</label>
                    <input type="radio" id="star1" name="rate" value="1" />
                    <label for="star1" title="text">1 star</label>
                </div>
            </div>
            <div className={styles.rateCaptions}>
                <i className="fa fa-hand-o-up fa-flip "></i>
                Rate Us
            </div>
        </div>

        <div className={styles.comments}>

            <div className={styles.commentTitle}>Kindly leave your feedback</div>
            <div>
                <div className={styles.commentForm}>
                    <textarea required name="comments">

                    </textarea>
                    <button className={styles.applicationButton}>Submit</button>
                </div>
            </div>
        </div>
    </form>

    const [feedbackTab, setfeedbackTab] = useState(feedback);



    async function handleSubmit(event) {
        event.preventDefault();

        const data = new FormData(event.target);

        const requestOptions = {
            method: 'POST',
            // headers: { 'Content-Type': 'application/json' },
            body: data
        };
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}add-comment`, requestOptions);

        const datas = await response.json();

        if (response.ok) {
            setfeedbackTab(afterFeedback)
        }
        else {
            alert('application not sent. please try sending the application again')
        }
    }


    return (
        <div className={styles.layout}>
            <>
                <div className={styles.headerTop}>
                    <div class={styles.headerTopleft}>
                        <span><i class="fa fa-envelope" aria-hidden="true"></i>&nbsp; <a href="mailto:info@pbcgh.com"> info@pbcgh.com</a></span>
                        <span><i class="fa fa-phone" aria-hidden="true"></i>&nbsp; <a href="tel:030-274-5754">030-274-5754</a></span>
                        <span><i class="fa fa-phone" aria-hidden="true"></i>&nbsp; <a href="tel:024-433-8440">024-433-8440</a></span>
                    </div>

                    <div class={styles.headerTopright}>



                        <span className={styles.headertop_fa}>
                            <a href="#" class="facebook"><i class="fa fa-facebook"></i></a>&nbsp;&nbsp;
                            <a href="#" class="google"><i class="fa fa-instagram"></i></a>&nbsp;&nbsp;
                            <a href="#" class="linkedin"><i class="fa fa-linkedin"></i></a>&nbsp;&nbsp;
                        </span>
                    </div>
                </div>
                <div className={navbar ? styles.header : styles.headerMinimized}>

                    <div className={styles.headerleft}>
                        <div className={styles.logoFrame}>
                            {/* <img alt="logo" src={Logos} /> */}


                            <div className={styles.logoCaption}>
                                18th Annual General Meeting


                            </div>
                        </div>
                    </div>
                    <div className={styles.headermiddle}>

                        <img alt="logo" src={Logos} height={50} width={50} />
                        {/* <a className={styles.signIn} onClick={SignIn}>Sign In</a> */}

                    </div>


                </div>
            </>
            <div className={styles.main}>
                <div className="carousel-wrapper" >
                    <Carousel infiniteLoop useKeyboardArrows autoPlay showThumbs={false} stopOnHover={false} interval={7000} transitionTime={500}>
                        <div className={styles.carousel}>

                            <img alt="picture" src={cocoa1} />
                            <div className={styles.overlay}>
                                <HeaderAnimation>
                                    <div className={styles.carouselContent}>
                                        <HeaderAnimation>
                                            <span className={styles.carouselText1}>OUR MISSION</span>
                                        </HeaderAnimation>
                                        <Animation2>
                                            <span className={styles.carouselText2}>Develop the estates of the Company to provide quality hospitality<br /> and warehousing services</span>
                                        </Animation2>
                                    </div>
                                </HeaderAnimation>
                            </div>
                        </div>
                        <div className={styles.carousel}>

                            <img alt="picture" src={cocoa2} />
                            <div className={styles.overlay}>
                                <HeaderAnimation>
                                    <div className={styles.carouselContent}>
                                        <HeaderAnimation>
                                            <span className={styles.carouselText1}>PBC LIMITED</span>
                                        </HeaderAnimation>
                                        <Animation2>
                                            <span className={styles.carouselText2}>
                                                A major player in the Cocoa industry showing tremendous growth in all aspect <br />of its core business activities</span>
                                        </Animation2>
                                    </div>
                                </HeaderAnimation>
                            </div>
                        </div>

                        <div className={styles.carousel}>
                            <img alt="picture" src={cocoa3} />
                            <div className={styles.overlay}>
                                <HeaderAnimation>
                                    <div className={styles.carouselContent}>
                                        <HeaderAnimation>
                                            <span className={styles.carouselText1}>PBC HAULAGE</span>
                                        </HeaderAnimation>
                                        <Animation2>
                                            <span className={styles.carouselText2}>Hauling of cocoa from the societies to the Depots for grading & sealing<br /></span>
                                            <br /> <br />
                                        </Animation2>
                                    </div>
                                </HeaderAnimation>
                            </div>
                        </div>
                    </Carousel>
                </div>

                <Row>
                    <div className={styles.rowContent}>
                        <div className={styles.rowContentLeft}>

                            <div className={styles.why_us_contents}>
                                <Parallaxword
                                    icon={<span class='fa-stack '>
                                        <i className='fa fa-circle fa-stack-2x'></i>
                                        <i className='fa fa-diamond fa-spin fa-stack-1x fa-inverse'></i>
                                    </span>}

                                    wordtitle={""} >
                                    <CountdownTimer targetDate={timeLeft} />
                                </Parallaxword>
                            </div>


                        </div>

                    </div>
                </Row>

                <Row>
                    <Parallaxrow >
                        <div ref={service} className={styles.rowContent2}>
                            <HeaderAnimation>
                                <div className={styles.rowHeadingCase}>
                                    <div className={styles.rowHeading}>  NOTICE OF ANNUAL GENERAL MEETING</div>
                                </div>
                            </HeaderAnimation>
                            <div className={styles.contentDivide}>

                                <div className={styles.ServiceRowContents}>
                                    <div className={styles.notice}>
                                        <p> <strong>Notice is here by given</strong> that the 18th Annual General meeting of PBC Limited will be held virtually on <br /><strong>Thursday, 21st December, 2023
                                            at 10:00 AM.</strong> a meeting link will be provided here on this website<br /> when the time is due.
                                            Kindly find and download attached pdf documents
                                        </p>

                                        <div className={styles.pdfContainer}>
                                            <a className={styles.pdfContent}

                                                href={AMENDED_NOTICE}
                                                download="Notice of Annual General Meeting (Latest)"
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                <div className={styles.fa_icons}>
                                                    <img src={pdf} alt="Notice of AGM Latest" />
                                                </div>
                                                <span className={styles.pdfContentHeader}>Notice of AGM Latest</span>
                                            </a>
                                            <a className={styles.pdfContent}

                                                href={MDPDF}
                                                download="MANAGING DIRECTOR’S REVIEW OF OPERATIONS"
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                <div className={styles.fa_icons}>
                                                    <img src={pdf} alt="MANAGING DIRECTOR’S REVIEW OF OPERATIONS" />
                                                </div>
                                                <div className={styles.pdfContentHeader}>Managing Director's Review of Operations</div>
                                            </a>
                                            <a className={styles.pdfContent}

                                                href={pdf1}
                                                download="Notice of AGM 2018"
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                <div className={styles.fa_icons}>
                                                    <img src={pdf} alt="Notice of AGM 2018" />
                                                </div>
                                                <div className={styles.pdfContentHeader}>Notice of AGM 2018</div>
                                            </a>
                                            <a className={styles.pdfContent}

                                                href={pdf2}
                                                download="Notice of AGM 2019"
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                <div className={styles.fa_icons}>
                                                    <img src={pdf} alt="Notice of AGM 2019" />
                                                </div>
                                                <div className={styles.pdfContentHeader}>Notice of AGM 2019</div>
                                            </a>
                                            <a className={styles.pdfContent}

                                                href={pdf3}
                                                download="Notice of AGM 2020"
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                <div className={styles.fa_icons}>
                                                    <img src={pdf} alt="Notice of AGM 2020" />
                                                </div>
                                                <div className={styles.pdfContentHeader}>Notice of AGM 2020</div>
                                            </a>
                                            <a className={styles.pdfContent}

                                                href={pdf4}
                                                download="Notice of AGM 2021"
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                <div className={styles.fa_icons}>
                                                    <img src={pdf} alt="Notice of AGM 2021" />
                                                </div>
                                                <div className={styles.pdfContentHeader}>Notice of AGM 2021</div>
                                            </a>
                                            <a className={styles.pdfContent}

                                                href={pdf5}
                                                download="Notice of AGM 2022"
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                <div className={styles.fa_icons}>
                                                    <img src={pdf} alt="Notice of AGM 2022" />
                                                </div>
                                                <div className={styles.pdfContentHeader}>Notice of AGM 2022</div>
                                            </a>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Parallaxrow >
                </Row>
            </div>

            <div ref={address} className={styles.footer} >
                <div className={styles.footerUpper}>
                    <div className={styles.logoFrame}>
                        <img alt="logo" src={Logos} />
                    </div>
                    <div class={styles.socialmedia}>
                        <a href="#" class="facebook"><i class="fa fa-facebook"></i></a>
                        <a href="#" class="twitter"><i class="fa fa-twitter"></i></a>
                        <a href="#" class="google"><i class="fa fa-instagram"></i></a>
                        <a href="#" class="linkedin"><i class="fa fa-linkedin"></i></a>

                    </div>
                </div>
                <div className={styles.footerSub}>
                    <span>Copyright 2022 <span style={{ color: "rgb(218, 96, 9)" }}>PBC CONFERENCE '23'.</span> All Rights Reserved  </span>
                    <span><a>Privacy Policy</a></span>
                </div>

            </div>
        </div>
    )

}