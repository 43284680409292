import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import InputForm from '../Form/inputForm'
import styles from "../Layout/layout.module.css"
import swal from 'sweetalert';
import Headers from "../Layout/Header";
import Footer from "../Layout/Footer";
import { HTTPClient } from "../Utils/HTTPClient/HTTPFetchClient";
import { ErrorAlert, SuccessAlert } from "../Component/Alerts/Alerts";

async function storeApplicant(credentials) {

  
    return fetch(`${process.env.REACT_APP_BASE_URL}login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(credentials)
    })
      .then(data => data.json())
   }
  
export default function SignUp(){

 

      const [count, setCount]=useState(1);
    const [loading, setLoading] = useState(false);
  
  const navigate=useNavigate();
    async function Onsubmit(credential, e){

        navigate('/sent-application');
      e.preventDefault();  
      setLoading(true);
      const response = await storeApplicant(credential);
    
     if (response.data) {

            navigate('/sent-application');
          window.location.reload();
      } else {
        setLoading(false)
        swal("Failed", JSON.stringify(response.errors[0]), "error");
      }
      response.errors &&  swal("Failed", JSON.stringify(response.errors[0]), "error");
    }
    useEffect(() => {
      window.scrollTo(0, 0)
    }, [])
  
    


      async function handleSubmit(event) {
          event.preventDefault();
         
if(event.target[1].value.length!=10)
{
  alert('phone number must not be less than 10');
  return;
}
        
        localStorage.setItem("name",event.target[0].value);
        localStorage.setItem("phone_number",event.target[1].value);


        navigate('/session/video');
        console.log("theses are the logged data",localStorage.getItem("name"), localStorage.getItem('phone_number'))
          // const data = new FormData(event.target);
        
    //       const requestOptions = {
    //         method: 'POST',
    //         // headers: { 'Content-Type': 'application/json' },
    //         body: data
    //     };
    //     const response = await fetch(`${process.env.REACT_APP_BASE_URL}add-applicant`,requestOptions);
    //     const datas = await response.json();
    //    if(response.ok)
    //    { 
    //     navigate('/sent-application');
    //    }
    //  else{
    //   alert('application not sent. please try sending the application again')
    //  }
        }
      
  async function Store(event) {
    event.preventDefault();
    // setIsLoading(true);
    try {
      const data = new FormData(event.target);
     
      let resp = await HTTPClient(
        `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_STORE_PARTICIPANT}`,
        {
          method: "POST",
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
            Accept: "application/json",
          },
          body: data,
        }
      );
      let response = await resp.json();

      if (!resp.ok) {
        // setIsLoading(false);
        let errMsg = response.errors.join("<br/>");
        ErrorAlert(errMsg, 5000);
        return;
      }

      let successMsg = ``;
      SuccessAlert(successMsg, 3000).then(() => {
        // navigate("/users");

          
        localStorage.setItem("name",event.target[0].value);
        localStorage.setItem("phone_number",event.target[1].value);
        console.log("theses are the logged data",localStorage.getItem("name"), localStorage.getItem('phone_number'))
        navigate('/session/video');
        // LoadPageData();
      });
    } catch (ex) {
      // setIsLoading(false);
      console.log(ex);
    }
    // setIsLoading(false);
  }

      
          return (
            <>
            <Headers/>
            <div className={styles.applicationPage} >
        <div className={styles.formHeight}> 
       
        <div className={styles.form}>  
        <div className={styles.formHeader}>REGISTRATION FORM</div>
            <form  className={styles.form} onSubmit={Store}>

              <dniv className={styles.inputGroup}>
              <label htmlFor="fullname">Enter Full Name</label>
              <input required id="fullname" name="name" type="text" />
              </dniv>
              <div className={styles.inputGroup}>
              <label htmlFor="phonenumber">Enter Phone Number</label>
              <input required id="phonenumber" name="phone_number" type="number" />
              </div>
              <div className={styles.inputGroup}>
              <button  className={styles.applicationButton}>Submit</button>
              </div>
            </form>
            </div>
            </div>
            </div>
            
            <Footer/>
            </>
          )
        
}


