import React from 'react';
import styles from './countdownstyle.module.css'

const DateTimeDisplay = ({ value, type, isDanger }) => {
  return (
    <div className={styles.countdowndate}>
    {value} {type};
    </div>
  );
};

export default DateTimeDisplay;