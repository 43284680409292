import styles from "../layout.module.css"
import 'font-awesome/css/font-awesome.min.css';

export default function Parallaxword2(props){
    
    return(
        <div className={styles.parallaxwordcontainer} style={{height:25}}>
            <div className={styles.parallaxfonticon} style={{ fontSize:"14px"}} >{props.icon}</div>
            <div className={styles.parallaxtextcontent}>
            <span className={styles.parallaxwordtitle} >{props.wordtitle}</span>
            <span className={styles.parallaxwordcontent} style={{color:"white", fontSize:"14px"}}>{props.children}</span>

            </div>
            <div className={styles.parallaxfonticon} style={{ fontSize:"14px"}} >{props.icon}</div>
        </div>
    )
}