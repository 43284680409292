import Swal from "sweetalert2";

export function SuccessAlert(successMsg, timer) {
  return Swal.fire({
    title: "Success",
    html: successMsg,
    icon: "success",
    timer: timer,
    showConfirmButton: false,
  });
}

export function ErrorAlert(errorMsg, timer) {
  return Swal.fire({
    title: "Error!",
    html: errorMsg,
    icon: "error",
    timer: timer,
    showConfirmButton: false,
  });
}
