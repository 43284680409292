
import styles from "./layout.module.css"
import Square from "./Headercomponents/square"
import { useState } from "react"
import 'font-awesome/css/font-awesome.min.css';
import Logos from "../resources/logo.png"
export default function Headers(props){
const [navbar, setNavbar]=useState(false)
const modifyHeader=()=>{
    // console.log(window.scrollY);
if(window.scrollY<80)
{
setNavbar(true);
}
else
{
    setNavbar(false);
}
}

    window.addEventListener("scroll", modifyHeader)
    const [ display, setDisplay ] = useState( 'none' )

    function handleClick() {

        if ( display === 'none' ) {

            setDisplay( 'block' )

        } else {

            setDisplay( 'none' )

        }

    }
    return (<>                <div className={styles.headerTop}>
        <div class={styles.headerTopleft}>
        <span><i class="fa fa-envelope" aria-hidden="true"></i>&nbsp; <a href="mailto:info@pbcgh.com"> info@pbcgh.com</a></span>
         <span><i class="fa fa-phone" aria-hidden="true"></i>&nbsp; <a href="tel:030-274-5754">030-274-5754</a></span>
         <span><i class="fa fa-phone" aria-hidden="true"></i>&nbsp; <a href="tel:024-433-8440">024-433-8440</a></span>
        </div>

        <div class={styles.headerTopright}>
       
        
  
        <span className={styles.headertop_fa}>
<a href="#" class="facebook"><i class="fa fa-facebook"></i></a>&nbsp;&nbsp;
  <a href="#" class="google"><i class="fa fa-instagram"></i></a>&nbsp;&nbsp;
   <a href="#" class="linkedin"><i class="fa fa-linkedin"></i></a>&nbsp;&nbsp;
   </span>
        </div>
         </div>  
         <div className={navbar ? styles.header : styles.headerMinimized}>

<div className={styles.headerleft}>
    <div className={styles.logoFrame}>
        {/* <img alt="logo" src={Logos} /> */}


        <div className={styles.logoCaption}>
            18th Annual General Meeting
        
        </div>
    </div>
</div>
<div className={styles.headermiddle}>
 
    <img alt="logo" src={Logos} height={50} width={50} />
  
 
</div>


</div>
        </>
    )
    
}