import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import InputForm from '../Form/inputForm'
import styles from "../Layout/layout.module.css"
import swal from 'sweetalert';
import Headers from "../Layout/Header";
import Footer from "../Layout/Footer";
import Logos from "../resources/logo.png"
import Marquee from "../MarqueeComponent/Marquee";
import Animation2 from "../Layout/Maincontentcomponents/Animation2";
import Parallaxword from "../Layout/Maincontentcomponents/Parallaxword";
import Parallaxword2 from "../Layout/Maincontentcomponents/Parallaxword2";
import {HTTPClient} from "../Utils/HTTPClient/HTTPFetchClient";
import {ErrorAlert, SuccessAlert} from "../Component/Alerts/Alerts"
import YouTube from "react-youtube";

async function storeApplicant(credentials) {


 

  return fetch(`${process.env.REACT_APP_BASE_URL}/video`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(credentials)
  })
    .then(data => data.json())
}

export default function Video() {

  let { token, name } = useParams();

  const [data, setData] = useState([]);
  const [tableData, setTableData] = useState([]);

  const [count, setCount] = useState(1);
  const [loading, setLoading] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [comment, setComment]=useState([])

  const navigate = useNavigate();
  async function Onsubmit(credential, e) {

    navigate('/sent-application');
    e.preventDefault();
    setLoading(true);
    const response = await storeApplicant(credential);

    if (response.data) {

      navigate('/sent-application');
      window.location.reload();
    } else {
      setLoading(false)
      swal("Failed", JSON.stringify(response.errors[0]), "error");
    }
    response.errors && swal("Failed", JSON.stringify(response.errors[0]), "error");
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])



  async function LoadPageData() {
   
    try {
      var resp = await HTTPClient(
        `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_COMMENTS}/${localStorage.getItem('phone_number')}`,
        {
          method: "GET",
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
            Accept: "application/json",
          },
        }
      );
      var response = await resp.json();

  
      if (!resp.ok) {
        console.log(response.errors.join("<br/>"));
        return;
      }
      setData(response.data)
      PopulateTable(response.data);
    } catch (ex) {
      console.log(ex);
    }
   
  }
  useEffect(() => {
    LoadPageData();
  }, []);

  async function Submit(event) {
    event.preventDefault();
    setIsLoading(true);
    console.log(event.target[5].value)
    try {
      const data = new FormData(event.target);
      data.append('name', localStorage.getItem('name'));
      data.append('phone_number', localStorage.getItem('phone_number'));
  
      let resp = await HTTPClient(
        `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_STORE_RESOLUTION}`,
        {
          method: "POST",
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
            Accept: "application/json",
          },
          body: data,
        }
      );
      let response = await resp.json();

      if (!resp.ok) {
        setIsLoading(false);
        let errMsg = response.errors.join("<br/>");
        ErrorAlert(errMsg, 5000);
        return;
      }

      let successMsg = `Done`;
      SuccessAlert(successMsg, 3000).then(() => {
        // navigate("/users");
        localStorage.setItem('resolutionStatus','done');
        LoadPageData();
      });
    } catch (ex) {
      setIsLoading(false);
      console.log(ex);
    }
    setIsLoading(false);
  }



  async function Store(event) {
    event.preventDefault();
    setIsLoading(true);
    try {
      const data = new FormData(event.target);
      data.append('name', localStorage.getItem('name'));
      data.append('phone_number', localStorage.getItem('phone_number'));
      let resp = await HTTPClient(
        `${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_STORE_COMMENT}`,
        {
          method: "POST",
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
            Accept: "application/json",
          },
          body: data,
        }
      );
      let response = await resp.json();

      if (!resp.ok) {
        setIsLoading(false);
        let errMsg = response.errors.join("<br/>");
        ErrorAlert(errMsg, 5000);
        return;
      }

      let successMsg = `Comment Added`;
      SuccessAlert(successMsg, 3000).then(() => {
        // navigate("/users");
        LoadPageData();
      });
    } catch (ex) {
      setIsLoading(false);
      console.log(ex);
    }
    setIsLoading(false);
  }


  async function handleSubmit(event) {
    event.preventDefault();


    // const data = new FormData(event.target);

    // const requestOptions = {
    //   method: 'POST',
    //   // headers: { 'Content-Type': 'application/json' },
    //   body: data
    // };
    // const response = await fetch(`${process.env.REACT_APP_BASE_URL}add-applicant`, requestOptions);
    // const datas = await response.json();
    // if (response.ok) {
    //   navigate('/sent-application');
    // }
    // else {
    //   alert('application not sent. please try sending the application again')
    // }
  }



  function PopulateTable(newTable) {

    let TableData = newTable.map((table) => {
      return <span className={styles.commentPanel}>
            {table.comment}
          </span>
      
    
    });
    setTableData(TableData);
   
  }

  const actions = (video, index) => {
    return (
      <div className="btn-container">
        <button
          className="Comments"
          onClick={() => navigate("/video/comments", { state: video })}
        >
          <span class="bi bi-pencil-square"></span>Comments
        </button>
      </div>
    );
  };

  const status = (status) => {
    return  <form  className={styles.form} onSubmit={handleSubmit}>

    <dniv className={styles.inputGroup}>
    <label htmlFor="fullname">Enter Full Name</label>
    <input required id="fullname" name="fullname" type="text" />
    </dniv>
    <div className={styles.inputGroup}>
    <label htmlFor="phonenumber">Enter Phone Number</label>
    <input required id="phonenumber" name="phonenumber" type="number" />
    </div>
    <div className={styles.inputGroup}>
    <button  className={styles.applicationButton}>Submit</button>
    </div>
  </form>
  };
  const options = {
    height: '590',
    width: '9000',
    playerVars: {
      autoplay: 1,
      controls: 1,
    },
  };

  return (
    <>
      <Headers />
      <div className={styles.profile}>      
      
      <Parallaxword2  icon={<span class='fa-stack '>
                                        <i className='fa fa-circle fa-stack-2x'></i>
                                        <i className='fa fa-diamond fa-spin fa-stack-1x fa-inverse'></i>
                                    </span>}>Welcome {localStorage.getItem("name")}</Parallaxword2>
  </div>
      <div className={styles.applicationPage} >


{/* {token}{name} */}
        <div className={styles.videoFrame}>

          <div className={styles.rightPane}>

            <div className={styles.video}>
            {/* <iframe  className={styles.video}
 
  // src="https://www.youtube.com/embed/Oflbho9ZG2U?start=103" 
  src="http://197.251.204.42:1935/PBC-AGM23/myStream/playlist.m3u8"
  title="YouTube video player" 
  frameborder="0" 
  allow="accelerometer; 
  autoplay; 
  clipboard-write; 
  encrypted-media; 
  
  "
   allowfullscreen></iframe> */}

<iframe className={styles.video} src="https://www.youtube.com/embed/GpPynsTAxkU?si=hwmWJmU4Hf6-Buid" 
title="YouTube video player" frameborder="0"
 allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;
  
  " allowfullscreen></iframe>
{/* <YouTube videoId="4-HUEbqZ0LI" options={options}/> */}
            </div>
            <div className={styles.videoCaption}>
              <div className={styles.videoCaption1}>
                18TH ANNUAL GENERAL MEETING OF PBC LIMITED
              </div>
              <div className={styles.videoCaption2}>
              DATE: <strong>THURSDAY, 21ST DECEMBER, 2023.</strong>         &nbsp;   &nbsp;         TIME: <strong>10:00AM</strong>
              </div>

            </div>
            <div className={styles.inputComment}>

              <form className={styles.commentForm} onSubmit={Store}>


                <div className={styles.inputGroup}>
                  <textarea required id="comment" name="comment" type="text" placeholder="Type your question here"/>
                </div>


                <div className={styles.inputGroup}>
                  <button className={styles.applicationButton}>Add Comment</button>
                </div>
              </form>

            </div>

            <div className={styles.commentCard}>
              <div className={styles.repCards}>
              {tableData}
              </div>

        
            </div>

          </div>
          <div className={styles.leftPane}>
            {/* <div className={styles.rtitle}>Special Resolutions</div> */}
            <form className={styles.txtCard} onSubmit={Submit}>
            <ol>
            <li>
              <div className={styles.txtHeader}>
              AMENDMENT OF NOTICE
              </div>
              <div className={styles.txtRow}>
            <div>  <input type="radio"
               id="q1"
               name="q1"
               value="for"/>
               <label>Vote For</label>
               </div>
               <div>
                 <input type="radio"
               id="q13"
               name="q1"
               value="against"/>
               <label>Vote Against</label>
               </div>
<div>
                 <input type="radio"
               id="q14"
               name="q1"
               value="abstain"/>
               <label>Abstain</label>
               </div>
              </div>
              </li>
              <li>
              <div className={styles.txtHeader}>
              RECEIPT, CONSIDERATION AND ADOPTION OF: 
(a)	REPORT OF DIRECTORS
(b)	REPORT OF THE AUDITORS
(c)AUDITED FINANCIAL STATEMENTS

              </div>
              <div className={styles.txtRow}>
            <div>  <input type="radio"
               id="q12"
               name="q2"
               value="for"/>
               <label>Vote For</label>
               </div>
               <div>
                 <input type="radio"
               id="q13"
               name="q2"
               value="against"/>
               <label>Vote Against</label>
               </div>
<div>
                 <input type="radio"
               id="q14"
               name="q2"
               value="abstain"/>
               <label>Abstain</label>
               </div>
              </div>
              </li>
             

              <li>
              <div className={styles.txtHeader}>
              RATIFICATION OF APPOINTMENT OF DIRECTORS

              </div>
              <div className={styles.txtRow}>
            <div>  <input type="radio"
               id="q12"
               name="q3"
               value="for"/>
               <label>Vote For</label>
               </div>
               <div>
                 <input type="radio"
               id="q13"
               name="q3"
               value="against"/>
               <label>Vote Against</label>
               </div>
<div>
                 <input type="radio"
               id="q14"
               name="q3"
               value="abstain"/>
               <label>Abstain</label>
               </div>
              </div>
              </li>
              <li>
              <div className={styles.txtHeader}>
              APPROVAL OF DIRECTORS RETIRING BY ROTATION

              </div>
              <div className={styles.txtRow}>
            <div>  <input type="radio"
               id="q12"
               name="q4"
               value="for"/>
               <label>Vote For</label>
               </div>
               <div>
                 <input type="radio"
               id="q13"
               name="q4"
               value="against"/>
               <label>Vote Against</label>
               </div>
<div>
                 <input type="radio"
               id="q14"
               name="q4"
               value="abstain"/>
               <label>Abstain</label>
               </div>
              </div>
              </li>
              <li>
              <div className={styles.txtHeader}>
              APPROVAL OF DIRECTORS FEES

              </div>
              <div className={styles.txtRow}>
            <div>  <input type="radio"
               id="q12"
               name="q5"
               value="for"/>
               <label>Vote For</label>
               </div>
               <div>
                 <input type="radio"
               id="q13"
               name="q5"
               value="against"/>
               <label>Vote Against</label>
               </div>
<div>
                 <input type="radio"
               id="q14"
               name="q5"
               value="abstain"/>
               <label>Abstain</label>
               </div>
              </div>
              </li>
              <li>
              <div className={styles.txtHeader}>
              APPROVAL OF DIRECTORS RETIRING BY ROTATION

              </div>
              <div className={styles.txtRow}>
            <div>  <input type="radio"
               id="q12"
               name="q6"
               value="for"/>
               <label>Vote For</label>
               </div>
               <div>
                 <input type="radio"
               id="q13"
               name="q6"
               value="against"/>
               <label>Vote Against</label>
               </div>
<div>
                 <input type="radio"
               id="q14"
               name="q6"
               value="abstain"/>
               <label>Abstain</label>
               </div>
              </div>
              </li>
              <li>
              <div className={styles.txtHeader}>
              RATIFICATION OF APPOINTMENT OF MESSRS ADOM BOAFO & ASSOCIATES AS AUDITORS FOR THE YEARS 2019/2020, 2020/2021 and 2021/2022

              </div>
              <div className={styles.txtRow}>
            <div>  <input type="radio"
               id="q12"
               name="q7"
               value="for"/>
               <label>Vote For</label>
               </div>
               <div>
                 <input type="radio"
               id="q13"
               name="q7"
               value="against"/>
               <label>Vote Against</label>
               </div>
<div>
                 <input type="radio"
               id="q14"
               name="q7"
               value="abstain"/>
               <label>Abstain</label>
               </div>
              </div>
              </li>
              <li>
              <div className={styles.txtHeader}>
              APPROVAL OF APPOINTMENT OF MESSRS ADOM BOAFO & ASSOCIATES AS AUDITORS FOR 2022/2023 AND TO FIX ITS REMUNERATION

              </div>
              <div className={styles.txtRow}>
            <div>  <input type="radio"
               id="q12"
               name="q8"
               value="for"/>
               <label>Vote For</label>
               </div>
               <div>
                 <input type="radio"
               id="q13"
               name="q8"
               value="against"/>
               <label>Vote Against</label>
               </div>
<div>
                 <input type="radio"
               id="q14"
               name="q8"
               value="abstain"/>
               <label>Abstain</label>
               </div>
              </div>
              </li>

              <p>Special Resolutions</p>
                  <li>
              <div className={styles.txtHeader}>
                  APPROVAL OF CHANGE OF COMPANY NAME FROM PBC LIMITED TO PBC PLC
              </div>
              <div className={styles.txtRow}>
            <div>  <input type="radio"
               id="q12"
               name="q9"
               value="for"/>
               <label>Vote For</label>
               </div>
               <div>
                 <input type="radio"
               id="q13"
               name="q9"
               value="against"/>
               <label>Vote Against</label>
               </div>
<div>
                 <input type="radio"
               id="q14"
               name="q9"
               value="abstain"/>
               <label>Abstain</label>
               </div>
              </div>
              </li>
              <p>EQUITY INJECTION/RAISING OF CAPITAL:</p>
              <li>
              <div className={styles.txtHeader}>
              AUTHORIZATION OF THE BOARD OF DIRECTORS TO RAISE GHC1.5 BILLION EQUITY FROM THE GHANA STOCK EXCHANGE    
              </div>
              <div className={styles.txtRow}>
            <div>  <input type="radio"
               id="q2"
               name="q10"
               value="for"/>
               <label>Vote For</label>
               </div>
               <div>
                 <input type="radio"
               id="q10"
               name="q2"
               value="against"/>
               <label>Vote Against</label>
               </div>
<div>
                 <input type="radio"
               id="q2"
               name="q10"
               value="abstain"/>
               <label>Abstain</label>
               </div>
              </div>
              </li>
              <li>
              <div className={styles.txtHeader}>
              AUTHORIZATION OF THE BOARD OF DIRECTORS TO RAISE GHC1.5 BILLION THROUGH PARTNERSHIP WITH STRATEGIC INVESTOR(S)
              </div>
              <div className={styles.txtRow}>
            <div>  <input type="radio"
               id="q3"
               name="q11"
               value="for"/>
               <label>Vote For</label>
               </div>
               <div>
                 <input type="radio"
               id="q3"
               name="q11"
               value="against"/>
               <label>Vote Against</label>
               </div>
<div>
                 <input type="radio"
               id="q3"
               name="q11"
               value="abstain"/>
               <label>Abstain</label>
               </div>
              </div>
              </li>
              <li>
              <div className={styles.txtHeader}>
              AUTHORIZATION OF BOARD OF DIRECTORS  TO SELL SOME OF ITS ASSETS INCLUDING THE TAKORADI HARBOUR ROAD LAND AND ANY OTHERS THE BOARD MAY DEEM FIT.
              </div>
              <div className={styles.txtRow}>
            <div>  <input type="radio"
               id="q4"
               name="q12"
               value="for"/>
               <label>Vote For</label>
               </div>
               <div>
                 <input type="radio"
               id="q4"
               name="q12"
               value="against"/>
               <label>Vote Against</label>
               </div>
<div>
                 <input type="radio"
               id="q4"
               name="q12"
               value="abstain"/>
               <label>Abstain</label>
               </div>
              </div>
              </li>
                </ol>
              <button>Submit</button>
            </form>
          </div>
        </div>
      </div>

      <Footer />
    </>
  )

}


