import styles from "./layout.module.css"
import Footercards from "./Footercomponents/Footercards"
import Footerparagraph from "./Footercomponents/footerparagraph"
import Electricity from "./Headercomponents/electricity"
import Footeraddress from "./Footercomponents/footeraddress"
import Logo from "../resources/logo.svg"
import Logos from "../resources/logo.png"


export default function Footer(){
    return (

        <div  className={styles.footer} >
        <div className={styles.footerUpper}>
            <div className={styles.logoFrame}>
                <img alt="logo" src={Logos} />
            </div>
            <div class={styles.socialmedia}>
                <a href="#" class="facebook"><i class="fa fa-facebook"></i></a>
                <a href="#" class="twitter"><i class="fa fa-twitter"></i></a>
                <a href="#" class="google"><i class="fa fa-instagram"></i></a>
                <a href="#" class="linkedin"><i class="fa fa-linkedin"></i></a>

            </div>
        </div>
        <div className={styles.footerSub}>
            <span>Copyright 2022 <span style={{ color: "rgb(218, 96, 9)" }}>PBC CONFERENCE '23'.</span> All Rights Reserved  </span>
            <span><a>Privacy Policy</a></span>
        </div>

    </div>
    )
}