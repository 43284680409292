import React from 'react';
import DateTimeDisplay from './CountdownDisplay';
import { useCountdown } from './Countdown';
import styles from './countdownstyle.module.css'

const ExpiredNotice = () => {
  return (
    <div className="expired-notice">
      <span>Expired!!!</span>
      <p>Please select a future date and time.</p>
    </div>
  );
};

const ShowCounter = ({ days, hours, minutes, seconds }) => {
  return (
    <div  className={styles.countdown}>
 
        <DateTimeDisplay value={days} type={'Days'} isDanger={days <= 3} />
     
        <DateTimeDisplay value={hours} type={'Hours'} isDanger={false} />
     
        <DateTimeDisplay value={minutes} type={'Mins'} isDanger={false} />
  
        <DateTimeDisplay value={seconds} type={'Seconds'} isDanger={false} />
    
    </div>
  );
};

const CountdownTimer = ({ targetDate }) => {


  const [days, hours, minutes, seconds] = useCountdown(targetDate);

  if (days + hours + minutes + seconds <= 0) {
    return <ExpiredNotice />;
  } else {
    return (
      <ShowCounter
        days={targetDate.days}
        hours={targetDate.hours}
        minutes={targetDate.minutes}
        seconds={targetDate.seconds}
      />
    );
  }
};

export default CountdownTimer;